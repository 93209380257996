.header {
    display: flex;
    flex-direction: column;
    min-height: 300px;

    &__banner {
        position: relative;
        overflow: hidden;
        box-shadow: 0px 1px 15px 5px grey;
        opacity: 90%;
        height: 100%;
        background-image: url("../../images/banner.jpg");
        background-repeat: no-repeat;
        background-size: cover;

        &__buttons {
            display: flex;
            justify-content: right;
            margin: 5px;

            &__links {
                display: flex;
                align-items: center;
                padding: 5px;

                a {
                    color: white;
                    padding-right: 10px;
                }
            }

            &__language {
                display: flex;
                font-family: 'Nunito', sans-serif;
                color: white;
                border-radius: 5px;
                border: 1px solid black;
                margin: 5px;
                padding: 5px;

                &:hover {
                    cursor: pointer;
                    background: rgba(0, 0, 0, 0.3);
                }

                &:active {
                    cursor: pointer;
                    background: rgba(0, 0, 0, 0.7);
                }
            }
        }

        &__title {
            position: absolute;
            top: 20%;
            width: 100%;
            text-align: center;
            color: white;
            font-size: 6vw;
            font-family: 'Nanum Myeongjo', serif;
            text-shadow: 2px 2px 6px black;
        }

        &__subtitle {
            position: absolute;
            top: 50%;
            width: 100%;
            text-align: center;
            color: rgb(255, 255, 255);
            font-size: 2vw;
            font-family: 'Nunito', sans-serif;
            font-weight: 200;
            text-shadow: 2px 2px 2px rgb(0, 0, 0);
        }
    }
}

@media screen and (max-width: 500px) {
    .header__banner {
        &__title {
            font-size: 12vw;
        }

        &__subtitle {
            font-size: 8vw;
        }
    }
}

@media screen and (min-width: 500px) and (max-width: 1200px) {
    .header__banner {
        &__title {
            font-size: 8vw;
        }

        &__subtitle {
            font-size: 4vw;
        }
    }
}

@media screen and (min-width: 2200px) {
    .header__banner {
        &__title {
            font-size: 4vw;
        }

        &__subtitle {
            font-size: 1.5vw;
        }
    }
}