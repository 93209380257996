@use "variables";

html {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    background-color: blanchedalmond;
    height: 100%;
    width: 100%;
}