.gallery-clip {
    position: relative;
    left: 12%;

    &:hover {
        .background-wrapper {
            box-shadow: 0 0 40px 1px rgba(17, 134, 73, 0.708);
        }
    }

    #selector1 {
        position: absolute;
        bottom: 60%;
        left: 50px;
        width: 1px;
        height: 1px;
    }

    #selector2 {
        position: absolute;
        bottom: 35%;
        left: 50px;
        width: 1px;
        height: 1px;
    }

    .background-wrapper {
        overflow: hidden;
        width: 1000px;
        height: 504px;
    }

    &__background-img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        filter: brightness(60%);
        transition: all .8s;
    }

    &__text {
        position: absolute;
        bottom: 45%;
        left: 60%;
        font-size: 45px;
        color: white;
        font-family: 'Nunito', sans-serif;
        font-weight: 100;
        transition: all .8s;
    }

    &:hover {
        .gallery-clip__background-img {
            transform: scale(1.2);
        }

        .gallery-clip {
            &__text {
                transform: scale(1.2);
            }
        }

        cursor: pointer;
    }

    &__image-scroll {
        display: flex;
        max-width: 1000px;
        left: -275px;
        max-height: 504px;
        padding-right: 60px;
        position: absolute;
        bottom: 0%;
        height: auto;
        overflow: scroll;
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for Firefox */
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
            /* for Chrome, Safari, and Opera */
        }

        &__list {
            display: flex;
            flex-direction: column;

            &__list-item {
                list-style: none;
                margin: 10px 0px;
                height: 200px;
                width: 200px;
                box-shadow: 8px 8px 40px 5px rgb(17, 17, 15);
                border-radius: 10%;

                img {
                    height: 200px;
                    width: 200px;
                    object-fit: cover;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .gallery-clip {
        left: 0%;

        &__text {
            left: 42%;
            font-size: 4vw;
        }

        .background-wrapper {
            overflow: hidden;
            width: 100%;
            height: 100%;
        }

        &__image-scroll {
            display: none;
        }

        &:hover {
            .gallery-clip {
                &__text {
                    font-size: 5vw;
                }
            }

            cursor: pointer;
        }
    }
}