.page-section {
    display: flex;
    width: 100vw;
    justify-content: center;

    &--plain {
        background-color: none;
    }

    &--color {
        background-color: rgb(47, 69, 47);
    }

    &--neutral {
        background-color: rgb(203, 203, 203);
    }

    &__content {
        display: flex;
    }
}

@media screen and (max-width: 1200px) {
    .page-section {
        &__content {
            margin: 8%;
        }
    }
}

@media screen and (min-width: 1201px) {
    .page-section {
        &__content {
            margin: 5%;
        }
    }
}