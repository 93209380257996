.gallery-page {
    display: flex;
    align-items: center;
    width: inherit;

    &__content {
        display: flex;
        width: 100%;
        height: 100%;
    }
}