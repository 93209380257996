.home-page {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: inherit;

    &__meet-artist {
        display: flex;
        max-width: 1000px;
        justify-content: space-between;

        &__img {
            display: flex;
            height: fit-content;
            align-self: center;

            img {
                align-self: center;
                width: 100%;
                height: 100%;
                max-height: 100%;
            }
        }

        &__text {
            display: flex;
            flex-direction: column;
            padding-left: 50px;
            flex: 1;
            color: white;
            font-family: 'Nunito', sans-serif;
            font-weight: 100;

            &__title {
                font-family: 'Nanum Myeongjo', serif;
                font-weight: 500;
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__links {
            width: 100%;
            display: flex;
            justify-content: space-around;

            a {
                color: olivedrab;

                &:hover {
                    color: black;
                }
            }
        }
    }
}

@media screen and (max-width: 870px) {
    .home-page {
        &__meet-artist {
            flex-direction: column;

            &__text {
                margin-top: 8%;
                padding-left: 0;
                font-size: 3vw;

                &__title {
                    font-size: 6vw;
                    padding-bottom: 5%;
                    text-align: justify;
                }

                &__quote {
                    font-size: 5vw;
                    text-align: right;
                    padding-bottom: 5%;
                    text-align: justify;
                }

                &__body {
                    font-size: 4vw;
                    text-align: justify;
                }
            }
        }
    }
}

@media screen and (min-width: 871px) and (max-width: 1250px) {
    .home-page {
        &__meet-artist {
            &__text {
                font-size: 2vw;
                text-align: right;

                &__title {
                    font-size: 3vw;
                    text-align: right;
                    padding-bottom: 5%;
                }

                &__quote {
                    font-size: 2.5vw;
                    text-align: right;
                    padding-bottom: 5%;
                }

                &__body {
                    font-size: 2vw;
                    text-align: right;
                }
            }
        }
    }
}

@media screen and (min-width: 1251px) {
    .home-page {
        &__meet-artist {
            &__text {
                font-size: 16pt;
                text-align: right;

                &__title {
                    font-size: 30pt;
                    text-align: right;
                    padding-bottom: 10%;
                }

                &__quote {
                    font-size: 30px;
                    text-align: right;
                    padding-bottom: 5%;
                }

                &__body {
                    font-size: 16pt;
                    text-align: right;
                }
            }
        }
    }
}

.fa {
    font-size: 24px;
}