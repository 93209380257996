#root {
    height: inherit;
}

.app {
    display: flex;
    flex-direction: column;
    height: inherit;
    width: inherit;

    &__content {
        display: flex;
        flex-direction: column;
        height: inherit;
        width: inherit;

        &--isModal {
            overflow: hidden;
        }
    }
}