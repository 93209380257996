.modal {
    display: flex;
    align-content: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.902);

    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        height: 10px;
        width: 10px;
        color: white;
        font-family: 'Nunito', sans-serif;
        padding: 10px;
        border: white solid 1px;
        border-radius: 100%;
        font-size: 12pt;
        right: inherit;
        margin: 10px;

        &:hover {
            cursor: pointer;
        }
    }
}