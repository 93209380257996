.photo-gallery {
    display: flex;
    width: 100%;
    height: 100%;

    &__content {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;

        &__container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 90vw;

            &__arrow {
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;
                align-self: center;
                text-shadow: 2px 2px 6px black;
                font-size: 20pt;
                color: white;
                height: 200px;
                width: 100px;
                bottom: 5%;
                cursor: pointer;

                &:hover {
                    font-size: 40px;
                }
            }

            &__image {
                display: flex;
                flex-direction: column;
                align-self: center;
                align-items: center;
                width: 70vw;

                &__img {
                    display: flex;
                    height: auto;
                    width: auto;
                    max-height: 70vh;
                    max-width: 70vw;
                }

                &__description {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    color: white;
                    max-height: 20%;
                    padding: 20px 0;
                    font-family: 'Nunito', sans-serif;
                }
            }
        }
    }
}